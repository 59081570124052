<template>
  <div class="inspection">
    <!-- <div v-if="switchBtn" style="padding: 15px; display: flex; align-items: center; justify-content: flex-end; font-size: 12px;">
      Live Photo:
      <VanSwitch
        v-model="switchBtnIsLivePhotoValue"
        style="margin-left: 15px;"
        active-color="#07C160"
        inactive-color="#EE0A24"
      />
    </div> -->
    <div v-if="rejectReason" class="reject-reason">{{ rejectReason }}</div>

    <FuFormMin
      v-if="!loading"
      ref="inspectionForm"
      v-model="formData"
      :schema="formJSON"
      :lang="lang"
      :disabled="!canEdit"
    />

    <div v-if="canEdit" class="footer fu-fixed">
      <Button
        class="th-next-btn"
        type="danger"
        color="#d9000b"
        :disabled="!!loading"
        @click="needShowDialog ? showChangeDateDialog() : onSubmit()"
      >
        {{ $t('submit') }}
      </Button>
    </div>

    <!-- RP-4562 印尼Live Photo更新时间弹窗 -->
    <Popup
      v-model="isShowDialog"
      class="change-date-dialog"
      closeable
      :close-on-click-overlay="false"
      :style="{ width: '80%' }"
      @click-close-icon="cancelChange()"
    >
      <h1 class="dialog-title"> {{ $t('changeDateDialog.title') }}</h1>
      <p class="dialog-subtitle">{{ $t('changeDateDialog.subtitle') }}</p>

      <div class="dialog-field-container">
        <p>{{ $t('changeDateDialog.policyPeriod') }}<span class="must-input">*</span></p>
        <p>{{ displayCoveragePeriod }}</p>
      </div>

      <div class="dialog-field-container" @click="showDatetimePicker()">
        <p>{{ $t('changeDateDialog.startDate') }}<span class="must-input">*</span></p>

        <div class="date-container">
          <img
            src="@/assets/imgs/icon-calendar.png"
            alt=""
            class="copy-icon"
          >
          <p>{{ displayStartDate }}</p>
        </div>

      </div>

      <div class="dialog-field-container">
        <p>{{ $t('changeDateDialog.expDate') }}<span class="must-input">*</span></p>

        <div class="date-container">
          <img
            src="@/assets/imgs/icon-calendar.png"
            alt=""
            class="copy-icon"
          >
          <p>{{ displayExpDate }}</p>
        </div>

      </div>

      <div class="dialog-btn-group">
        <div class="dialog-btn-cancel" @click="cancelChange()">{{ $t('changeDateDialog.cancel') }}</div>
        <div class="dialog-btn-confirm" @click="confirmChange()">{{ $t('changeDateDialog.update') }}</div>
      </div>
    </Popup>

    <DatetimePicker
      v-if="isShowDatetimePicker"
      v-model="selectedDate"
      class="date-time-picker"
      type="date"
      title="Choose Date"
      :min-date="minDate"
      :columns-order="['day', 'month', 'year']"
      @confirm="confirmUpdateDate()"
      @cancel="cancelUpdateDate()"
    />
  </div>
</template>

<script>
import { Button, Dialog, Popup, DatetimePicker
  // Switch
} from 'vant'
import FuFormMin from '@/components/FuFormMin'
import { toastLoading } from '@/utils/index'
import dao from '../../utils/dao'
import { appendInspection, updateDate } from './api'
import { getInspectionInfo } from './api'
import { isDesktop } from '../../utils/dispatch'
import { getTenantRegion } from '@fu/core'
import { detectEmulation } from '../../utils'

export default {
  name: 'Inspection',
  components: {
    FuFormMin,
    Button,
    Popup,
    DatetimePicker
    // VanSwitch: Switch
  },
  data() {
    return {
      switchBtn: false,
      switchBtnIsLivePhotoValue: true,
      loading: null,
      canEdit: false,
      formData: {},
      formJSON: {},
      checkSchema: {},
      inspectionInfo: {},
      isIdCarInspection: false,
      isShowDialog: false,
      isShowDatetimePicker: false,
      selectedDate: new Date(),
      startDate: null,
      minDate: new Date(),
      oldDate: new Date(),
      coveragePeriod: 1
    }
  },
  provide() {
    return {
      switchBtnIsLivePhotoValue: () => { return this.switchBtnIsLivePhotoValue }
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang || 'vn'
    },
    quoteNo() {
      return this.$route.query.quoteNo
    },
    needOTP() {
      return dao.get('inspectionInfo')?.inspectionOtpInfo?.isOtpRequired ?? false
    },
    // 通过路由是否传有投保单号来区别是否需要提交给后台
    hasSubmit() {
      return !this.quoteNo
    },
    rejectReason() {
      if (this.inspectionInfo?.currentInspectionStatus == 3 && this.inspectionInfo.underwritingPlatformReason) {
        return this.inspectionInfo?.underwritingPlatformReason ?? ''
      } else {
        return ''
      }
    },
    isID() {
      return getTenantRegion() === 'ID'
    },
    // 是否显示 更改日期弹窗 （印尼 Live Photo 新增）
    needShowDialog() {
      return this.isIdCarInspection && this.isSubmitDateGreater()
    },
    displayStartDate() {
      return this.formatStartDate(this.selectedDate)
    },
    displayExpDate() {
      return this.formatExpDate(this.selectedDate)
    },
    displayCoveragePeriod() {
      const year = this.coveragePeriod > 1 ? this.$t('changeDateDialog.years') : this.$t('changeDateDialog.year')
      return `${this.coveragePeriod} ${year}`
    }
  },
  async activated() {
    if (await detectEmulation() || this.$route.query.door) { // door是后门，主要是用在后门打开页面
      this.init()
    } else {
      this.$toast(this.$t('invalidPlatform'))
      const delay = ms => new Promise(res => setTimeout(res, ms))
      await delay(1000)
      this.$router.go(-1)
    }
  },
  methods: {
    async init() {
      this.inspectionInfo = dao.get('inspectionInfo') || {}
      const token = dao.get('inspectionToken') || ''

      const params = {
        slipUid: this.inspectionInfo?.shareInspectionInfo?.orderSlipInspectionDTO?.slipUid
      }
      // inspection资料状态 1:required 2:submitted 3:rejected 4:finished
      if (![1].includes(this.inspectionInfo?.currentInspectionStatus)) {
        this.loading = toastLoading()
        const inspectionInfoView = await getInspectionInfo(params, token)
        const inspectionData = JSON.parse(inspectionInfoView?.carInspectionData || '{}')
        this.formData = inspectionData
        this.loading && this.loading.clear()
        this.loading = null
      }

      this.$nextTick(() => {
        this.$refs.inspectionForm && this.$refs.inspectionForm.resetValidate()
      })

      // 增加国家判断，只有印尼才会展示弹窗
      if (this.isID) {
        if (this.inspectionInfo && this.inspectionInfo.orderSlipInfo) {
          const orderSlipInfo = this.inspectionInfo.orderSlipInfo
          this.isIdCarInspection = orderSlipInfo.inspectionFlag
          this.startDate = orderSlipInfo.startTime
          this.coveragePeriod = orderSlipInfo.coveragePeriod
        }
      }

      this.getInspectionInfo()
      this.getCanEdit()
    },
    getCanEdit() {
      const inspectionInfo = dao.get('inspectionInfo') || {}
      this.canEdit = [1, 3].includes(inspectionInfo?.currentInspectionStatus) && !isDesktop
    },
    async getInspectionInfo() {
      const inspectionInfo = dao.get('inspectionInfo') || {}

      this.formJSON = JSON.parse(inspectionInfo?.inspectionTemplateOutDTO?.displaySchema || '{}')
      if (inspectionInfo?.inspectionTemplateOutDTO?.displaySchema?.includes('Aiphoto')) {
        this.switchBtn = true
      }
      this.checkSchema = inspectionInfo?.inspectionTemplateOutDTO?.checkSchema || {}
    },
    // 提交数据
    onSubmit() {
      this.$refs.inspectionForm.validate().then(res => {
        if (this.hasSubmit) {
          this.handleSubmit()
          return
        }
      }).catch(error => {
        console.log('----fail----')
        console.log(error)
      })
    },

    // 提交操作
    handleSubmit() {
      const json = JSON.stringify(this.formData)
      const cb = (action, done) => {
        if (done) done()
        if (action === 'confirm') {
          return
        }
        this.$nextTick(() => {
          Dialog.confirm({
            message: this.getDialogMessage(),
            theme: 'round-button',
            confirmButtonText: this.$t('no'),
            // confirmButtonColor: '#C82A21',

            showCancelButton: true,
            cancelButtonText: this.$t('yes'),
            // cancelButtonColor: '#fff',
            beforeClose: this.beforeClose,
            className: 'inspection-dialog'
          })
        })
      }
      if (json.includes('Aiphoto') && json.includes('"valid":false')) {
        Dialog.confirm({
          message: this.$t('livephoto.invalidTips'),
          theme: 'round-button',
          confirmButtonText: this.$t('no'),
          // confirmButtonColor: '#C82A21',

          showCancelButton: true,
          cancelButtonText: this.$t('yes'),
          // cancelButtonColor: '#fff',
          beforeClose: cb,
          className: 'inspection-dialog'
        })
        return
      }
      cb()
    },
    async beforeClose(action, done) {
      const inspectionInfo = dao.get('inspectionInfo') || {}

      // 取消 这个设计稿是把确认放在左边
      // 所以组件的confirm 相当于取消
      if (action === 'confirm') {
        done()
        return
      }

      if (this.needOTP) {
        dao.set('inspectionAppendForm', {
          carInspectionData: JSON.stringify(this.formData),
          carInspectionSchema: this.checkSchema,
          slipUid: inspectionInfo?.shareInspectionInfo?.orderSlipInspectionDTO?.slipUid
        })
        done()
        this.$router.push(`/inspection/verification`)
      } else {
        const token = dao.get('inspectionToken')

        const params = {
          carInspectionData: JSON.stringify(this.formData),
          carInspectionSchema: this.checkSchema,
          slipUid: inspectionInfo?.shareInspectionInfo?.orderSlipInspectionDTO?.slipUid
        }

        const res = await appendInspection(params, token)

        done()

        if (!res || res.code !== 200) {
          this.$toast(res.message)
        } else {
          this.canEdit = false
          this.$router.push('/inspection/orderDetail/?token=' + token)
        }
      }
    },
    getDialogMessage() {
      return `
        <i class="van-icon van-icon-warning-o" style="margin-bottom: 25px; color: #C82A21; font-size: 65px;"></i>
        <p class="tit" style="font-weight: 900; font-size: 14px; color: #C82A21;">${this.$t('confirmSubmit')}</p>
        <p style="font-size: 12px">${this.$t('inspectionSubmitTip')}</p>
      `
    },

    async showChangeDateDialog() {
      this.isShowDialog = true
    },

    async showDatetimePicker() {
      this.isShowDatetimePicker = true
    },
    // Popup Cancel Button
    cancelChange() {
      this.isShowDialog = false
      this.isShowDatetimePicker = false
      this.selectedDate = this.oldDate
    },
    // Popup Confirm Button
    async confirmChange() {
      const token = dao.get('inspectionToken')
      const startDate = this.convertDateToLong(this.selectedDate)
      const params = {
        slipUid: this.inspectionInfo?.shareInspectionInfo?.orderSlipInspectionDTO?.slipUid,
        startTime: startDate
      }
      const res = await updateDate(params, token)

      if (!res || (res.code && res.code != 200)) { return }

      if (res) {
        this.startDate = res.startTime
      }

      this.isShowDialog = false
      this.isShowDatetimePicker = false
      this.oldDate = this.selectedDate
    },

    // DatetimePicker Cancel Button
    cancelUpdateDate() {
      this.isShowDatetimePicker = false
      this.selectedDate = this.oldDate
    },

    // DatetimePicker Confirm Button
    confirmUpdateDate() {
      this.isShowDatetimePicker = false
    },

    formatStartDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    },

    formatExpDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear() + (this.coveragePeriod ?? 0) // Add n year(s) to expDate based on coveragePeriod
      return `${day}/${month}/${year}`
    },

    convertDateToLong(newDate) {
      const currentDate = new Date(newDate)

      const year = currentDate.getFullYear()
      const month = currentDate.getMonth()
      const day = currentDate.getDate()

      const updatedDate = new Date(Date.UTC(year, month, day, 12, 0, 0))

      return updatedDate.getTime()
    },
    // 印尼 Live Photo 提交日期 > 下单日期则返 true
    isSubmitDateGreater() {
      const todayDate = new Date()
      const startDate = new Date(this.startDate)

      const todayDay = todayDate.getDate()
      const todayMonth = todayDate.getMonth()
      const todayYear = todayDate.getFullYear()

      const startDay = startDate.getDate()
      const startMonth = startDate.getMonth()
      const startYear = startDate.getFullYear()

      if (todayYear > startYear) {
        return true
      } else if (todayYear === startYear && todayMonth > startMonth) {
        return true
      } else if (todayYear === startYear && todayMonth === startMonth && todayDay > startDay) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  .van-button {
    // background: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    .van-button__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      // color: #FFFFFF;
    }
  }

  .van-button + .van-button {
    margin-left: 10px;
  }
}
.inspection {
  padding-bottom: 50px;
  background-color: #fbfbfb;

  .reject-reason {
    font-size: 15px;
    padding: 20px;
    margin: 10px 20px 0px 20px;
    background-color: #FFB8B8;
    color: #CC0404;
    border-radius: 10px;
  }

  .otp-desc {
    background: #D9D9D9;
    // font-size: 12px;
    margin: 15px;
    padding: 10px;
  }

}
.change-date-dialog {
  border-radius: 10px;
  padding: 24px 16px;

  .dialog-title {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .dialog-subtitle {
    margin: 10px 0;
    font-size: 10px;
    color: #939597;
    padding: 10px 0px;
    text-align: center;
  }

  .dialog-field-container {
    background-color: #F5F5F5;
    border-radius: 5px;
    padding: 8px 12px;
    margin-top: 16px;
    margin-bottom: 16px;

    .must-input {
      color: #E74039;
    }

    p {
      font-size: 10px;
      color: #939597;
    }

    .date-container {
      display: inline-block;
      margin-left: auto;
      display: flex;
      align-items: center;

      .copy-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .dialog-btn-group {
    display: flex;
    gap: 6px;
    font-size: 12px;

    .dialog-btn-cancel, .dialog-btn-confirm {
      flex: 1;
      padding: 12px 16px;
      border-radius: 5px;
      text-align: center;
    }

    .dialog-btn-cancel {
      background-color: #F5F5F5;
      border: 2px solid #D3D3D3;
    }
    .dialog-btn-confirm {
      background-color: #E74039;
      border: 2px solid #E74039;
      color: #FFFFFF;
    }
  }
}

.date-time-picker {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;

  .date-time-picker .van-picker-column__item {
    font-size: 11px !important;
  }
}
</style>

<style lang="scss">
.inspection-dialog {
  .van-dialog__footer {
    justify-content: space-between;
  }
  .van-dialog__message {
    white-space: pre-line;
  }
  .van-dialog__cancel,
  .van-dialog__confirm {
    width: 130px;
    border: 1.5px solid #C82A21;
    box-shadow: 0px .35px 1.4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    background: none;
  }
  .van-dialog__cancel {
    background-color: #FFFFFF;
    color: #C82A21;
  }
  .van-dialog__confirm {
    margin-left: 10px;
    background-color: #C82A21;
  }
}
</style>
